import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { ConfirmComponent } from './confirm/confirm.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { RegionInfoGuard } from './shared/guards/region-info.guard';

export const RouterDefinitions: Routes = [
  {
    path: AppRoutes.CONFIRM,
    component: ConfirmComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: `${AppRoutes.APP}`,
    loadChildren: () => import('./core/core.module').then((m) => m.CoreModule),
    canLoad: [XpoAuthenticationGuard, RegionInfoGuard],
  },
  {
    path: '',
    redirectTo: AppRoutes.CONFIRM,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false })],
  exports: [RouterModule, CommonModule],
})
export class AppRoutingModule {}
