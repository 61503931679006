import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { ConfirmEnvironmentComponent } from './confirm-environment/confirm-environment.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
  ConfirmEnvironmentComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    XpoButtonModule,
    XpoDialogModule,
  ],
  exports: [...dialogs],
})
export class DialogModule {}
