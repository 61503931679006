import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SymRegions, XpoLtlGlobalEnsembleFilterService } from '@xpo-ltl/symphony-ensemble-ui';
import { RegionGroup } from './region-group.model';

@Component({
  selector: 'confirm-environment',
  templateUrl: './confirm-environment.component.html',
  styleUrls: ['./confirm-environment.component.scss'],
})
export class ConfirmEnvironmentComponent implements OnInit {
  isProd: boolean;
  inputValue: string;
  regionGroups: RegionGroup[];
  enableConfirm: boolean;

  selectFormControl = new FormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<ConfirmEnvironmentComponent>,
    private globalFilterService: XpoLtlGlobalEnsembleFilterService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.regionGroups = this.buildRegionGroups();
  }

  ngOnInit() {
    this.isProd = this.data.prod;
  }

  checkProdInput(event: KeyboardEvent): void {
    this.enableConfirm = this.inputValue.toUpperCase() === 'PROD';
    if (this.enableConfirm) {
      this.selectFormControl.setValue('WAVE');
      if (event.key === 'Enter') {
        this.dialogRef.close(this.selectFormControl.value);
      }
    }
  }

  disableConfirm(): boolean {
    if (this.isProd) {
      return !this.enableConfirm;
    } else {
      return this.selectFormControl.hasError('required');
    }
  }

  onConfirm() {
    this.dialogRef.close(this.selectFormControl.value);
  }

  private buildRegionGroups(): RegionGroup[] {
    const devRegions: RegionGroup = {
      name: '-- DEV --',
      regions: this.globalFilterService.devRegions,
    };
    const sysRegions: RegionGroup = {
      name: '-- SYSTEM --',
      regions: this.globalFilterService.systemRegions,
    };
    const preProdRegions: RegionGroup = {
      name: '-- PRE-PROD --',
      regions: [SymRegions.TCTSS],
    };
    return [devRegions, sysRegions, preProdRegions];
  }
}
