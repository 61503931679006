<div class="confirm-environment-dialog">
  <h1 mat-dialog-title>Confirm Environment</h1>

  <div *ngIf="isProd" mat-dialog-content>
    <p>
      Please type "PROD" below to confirm that you really want to access Production.
    </p>
    <br />
    <input
      [(ngModel)]="inputValue"
      (keyup)="checkProdInput($event)"
      matInput
      maxlength="4"
      placeholder="Type PROD here"
    />
  </div>
  <div *ngIf="!isProd" mat-dialog-content>
    <p>
      Please select a Region to start.
    </p>
    <br />
    <mat-form-field>
      <mat-label>Test Regions</mat-label>
      <mat-select [formControl]="selectFormControl" required>
        <mat-optgroup *ngFor="let group of regionGroups" [label]="group.name">
          <mat-option *ngFor="let region of group.regions" [value]="region">
            {{ region }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button (click)="onConfirm()" [disabled]="disableConfirm()" color="primary" mat-button type="button">
      Continue
    </button>
  </div>
</div>
