import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoFiltersService } from '@xpo-ltl/ngx-ltl-board';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { SymphonyFilterModule } from '@xpo-ltl/symphony-ensemble-ui';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DialogModule } from './dialogs/dialog.module';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, ConfirmComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    XpoButtonModule,
    XpoDialogModule,
    XpoSnackBarModule,
    SymphonyFilterModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    XpoFiltersService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
