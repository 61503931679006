import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { SymphonyEnsembleApiService } from '@xpo-ltl/sdk-symphonyensemble';
import { SymEnConfigService, XpoLtlGlobalEnsembleFilterService } from '@xpo-ltl/symphony-ensemble-ui';
import { ConfirmEnvironmentComponent } from '../dialogs/confirm-environment/confirm-environment.component';
import { AppRoutes } from '../shared/enums/app-routes.enum';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  providers: [SymphonyEnsembleApiService],
})
export class ConfirmComponent implements OnInit {
  isProd: boolean;

  constructor(
    private authConfigService: XpoLtlAuthConfigLoaderService,
    private authService: XpoLtlAuthenticationService,
    private configManagerService: ConfigManagerService,
    private globalFilterService: XpoLtlGlobalEnsembleFilterService,
    private symphonyEnsembleService: SymphonyEnsembleApiService,
    private symEnConfigService: SymEnConfigService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.isProd = configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  ngOnInit(): void {
    const dialogRef = this.dialog.open(ConfirmEnvironmentComponent, {
      width: this.isProd ? '600px' : '400px',
      data: {
        prod: this.isProd,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((region) => {
      if (region) {
        this.authService.logout();
        this.authService.initAuthSetup$(region).subscribe((info) => {
          this.authConfigService.apiUrl = info.apiUrl;
          this.configManagerService.setSetting('apiUrl', info.apiUrl);
          this.globalFilterService.publishRegionChange(region);
          // This call triggers filter changed event.
          this.globalFilterService.regionFilter = region;
          this.symphonyEnsembleService.switchApiContextRoot('radar/1.0');
          this.symphonyEnsembleService.loggedInUser().subscribe((user) => {
            this.symEnConfigService.loggedInUser = user;
          });
          this.router.navigate([AppRoutes.APP, AppRoutes.WELCOME]);
        });
      }
    });
  }
}
